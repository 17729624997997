<template>
  <div class="container" v-title :data-title="$t('i18n.maintenanceItemsConfiguration')">
    <div id="outer-title">
      {{ $t("i18n.maintenanceItemsConfiguration") }}
    </div>
    <p class="fl failTxt">{{ $t("i18n.dangerTips") }}</p>
    <div v-loading="loading">
      <jl-table :tableData="tableData" @handleEdit="handleEdit" @handleDelete="handleDelete" @getEditData="getEditData"
        @init="init" :options="options" :columns="columns" :operates="operates" :total="total"
        :height="$defined.HEIGHT - 260 + 'px'">
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "MaintenanceItemsConfigitemInfo",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("新增维保项次"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑维保项次"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除维保项次"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
        index: true, // 序号
      },
      columns: [
        // 需要展示的列
        {
          prop: "sortNumber",
          label: "number",
          align: "center",
          type: "input",
        },
        {
          prop: "itemName",
          label: "itemContent",
          align: "left",
          width: 400,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isRecordProcess",
          label: "recordOperational",
          align: "center",
          type: "switch",
          text: { aText: "yes", aVal: 1, iText: "no", iVal: 0 },
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            let str = row.isRecordProcess == 1 ? "yes" : "no";
            return t("i18n." + str);
          },
        },
        {
          prop: "isBeaconNo",
          label: "validateBeaconNo",
          align: "center",
          type: "switch",
          text: { aText: "yes", aVal: 1, iText: "no", iVal: 0 },
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            let str = row.isBeaconNo == 1 ? "yes" : "no";
            return t("i18n." + str);
          },
        },
        {
          prop: "isQr",
          label: "scanCode",
          align: "center",
          type: "switch",
          text: { aText: "yes", aVal: 1, iText: "no", iVal: 0 },
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            let str = row.isQr == 1 ? "yes" : "no";
            return t("i18n." + str);
          },
        },
        {
          prop: "sign",
          label: "codeLocation",
          align: "center",
          type: "select",
          props: { label: "signName", value: null },
          data: [],
          formatter: (row) => {
            return row.sign instanceof Object ? row.sign.signName : row.sign;
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isPicture",
          label: "takePictures",
          align: "center",
          type: "switch",
          text: { aText: "yes", aVal: 1, iText: "no", iVal: 0 },
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            let str = row.isPicture == 1 ? "yes" : "no";
            return t("i18n." + str);
          },
        },
        {
          prop: "isVlog",
          label: "shootVideo",
          align: "center",
          type: "switch",
          text: { aText: "yes", aVal: 1, iText: "no", iVal: 0 },
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            let str = row.isPicture == 1 ? "yes" : "no";
            return t("i18n." + str);
          },
        },
        {
          prop: "pictureNumber",
          label: "numberOfPictures",
          align: "center",
          type: "input",
        },
        // {
        //   prop: "vlogNumber",
        //   label: "numberOfVideo",
        //   align: "center",
        //   type: "input",
        // },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      loading: true,
      editData: {},
    });

    const init = async () => {
      let obj = route.query;
      state.loading = true;
      const { data } = await proxy.$api.maintenance.getConfigData(obj);
      state.tableData = data;
      state.loading = false;
    };

    const getNotSign = async () => {
      let obj = route.query;
      const { data } = await proxy.$api.maintenance.getNotSign(obj);
      state.columns.map((item) => {
        if (item.label === "codeLocation") {
          item.data = data;
        }
      });
    };
    getNotSign();

    const handleEdit = async (val) => {
      console.log(val,"Valevalava");
      if(val.isVlog == 1){
          val.vlogNumber = 1
      }else{
        val.vlogNumber = 0
      }
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      if (val.sign instanceof Object) {
        val.signId = val.sign.id;
        val.sign = val.sign.signName;
      }
      for (let key in route.query) {
        val[key] = route.query[key];
      }
      val.id
        ? await proxy.$api.maintenance.editConfigData(val)
        : await proxy.$api.maintenance.addConfigData(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init();
    };

    const handleDelete = (row) => {
      proxy.$api.maintenance.delConfigData(row.id).then(() => {
        init();
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const remoteEleMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "number";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state.columns[index].data = data.data;
              state.columns[index].loading = false;
            });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      remoteEleMethod,
      getEditData,
    };
  },
};
</script>
